<template>
  <vs-popup fullscreen title="Detalle" :active.sync="popupActiveLocal">
      <ag-grid-vue
          ref="popupDetails"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="items"
          colResizeDefault="shift"
          :animateRows="true"
          :pagination="false"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl">
      </ag-grid-vue>
  </vs-popup>
</template>
<script>
import { AgGridVue } from "@ag-grid-community/vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

export default {
  props: {
      data: {
          type: Array,
          required: true
      },
      popupActive: {
          type: Boolean,
          required: true
      }
  },
  data() {
      return {
          gridOptions: {
              alignedGrids: [],
              suppressHorizontalScroll: true,
          },
          defaultColDef: {
              sortable: true,
              editable: false,
              resizable: true,
              suppressMenu: true
          },
          columnDefs: [
              {
              headerName: 'ID',
              field: 'id',
              filter: true,
              width: 120,
              // hide: this.$acl.check('school')
              },
              {
              headerName: 'Producto',
              field: 'product',
              filter: true,
              },
              {
              headerName: 'Categoría',
              field: 'category',
              filter: true,
              },
              {
              headerName: 'Valor de Venta',
              field: 'sale_amount',
              filter: true,
              valueFormatter: params => this.$options.filters.currencySign(params.data.sale_amount, '$ '),
              },
              {
              headerName: '% Comisión',
              field: 'tiptap_commission_percent',
              filter: true,
              valueFormatter: params => this.$options.filters.currencyPercentage(params.data.tiptap_commission_percent, ' %'),
              },
              {
              headerName: '$ Comisión',
              field: 'tiptap_commission_amount',
              filter: true,
              valueFormatter: params => this.$options.filters.currencySign(params.data.tiptap_commission_amount, '$ '),
              },
              // {
              // headerName: 'Total por Venta',
              // field: 'total',
              // filter: true,
              // valueFormatter: params => this.$options.filters.currencySign(params.data.base_amount + params.data.tiptap_amount_commission_guardian + params.data.tiptap_amount_commission_main_institution, '$ '),
              // valueGetter: params => {
              //     return params.data.base_amount + params.data.tiptap_amount_commission_guardian + params.data.tiptap_amount_commission_main_institution
              // }
              // },
          ],
          // popupActiveLocal: JSON.parse(this.popupActive)
      }
  },
  components: {
      AgGridVue,
  },
  computed: {
      popupActiveLocal: {
          get() {
              return JSON.parse(this.popupActive)
          },
          set() {
              this.$emit('updatePopupActive')
          }
      },
      items() {
          return this.data
      }
  },
}
</script>
