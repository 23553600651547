<template>
    <div>
        <vx-card>
                <h5 style="margin-bottom:9px">Ingresos por Pago de Servicios</h5>
                <!-- TABLE ACTION ROW -->
                <div class="flex flex-wrap justify-between items-center">
                    <!-- ITEMS PER PAGE -->
                    <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left"></div>

                    <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
                    <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right mt-3">
                        <p style="margin-top:-60px; margin-right:-40px; font-size:10px"> Desde</p>
                        <date-picker :language="languages.es" v-model="from" :first-day-of-week="1" label="Desde" placeholder="Desde" style="margin-right:-10px"></date-picker>
                        <p style="margin-top: -60px; margin-right:-40px; font-size:10px"> Hasta</p>
                        <date-picker :language="languages.es" v-model="to" type="datetime" label="Hasta" placeholder="Hasta" style="margin-right:-10px"></date-picker>
                        <vs-input
                            class="mb-4 md:mb-0 mr-4"
                            v-model="searchQuery"
                            @input="updateSearchQuery"
                            placeholder="Buscar..."/>
                        <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Exportar</vs-button>
                    </div>
                </div>
                <ag-grid-vue
                    ref="agGridTable"
                    :gridOptions="gridOptions"
                    class="ag-theme-material w-100 my-4 ag-grid-table"
                    style="max-height: 450px !important;"
                    :columnDefs="columnDefs"
                    :defaultColDef="defaultColDef"
                    :rowData="sales"
                    @first-data-rendered="onFirstDataRendered"
                    rowSelection="multiple"
                    colResizeDefault="shift"
                    :animateRows="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :suppressPaginationPanel="true"
                    :enableRtl="$vs.rtl"
                    :modules="modules">
                </ag-grid-vue>
                <ag-grid-vue style="height: 100px; flex: none;"
                    class="ag-theme-material w-100 ag-grid-table total"
                    :gridOptions="bottomGridOptions"
                    :headerHeight="0"
                    :columnDefs="columnDefsFooter"
                    :rowData="salesFooter"
                    :modules="modules">
                </ag-grid-vue>
            </vx-card>
            <popup-detail
                :data="popupData"
                :popupActive="popupActive"
                @updatePopupActive="updatePopupActive(false)"
            ></popup-detail>
    </div>
</template>

<script>
import * as lang from 'vuejs-datepicker/src/locale';
import { AgGridVue } from "@ag-grid-community/vue"
import reportModule from '@/store/report/moduleReport.js'
import DatePicker from "vuejs-datepicker";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import PopupDetail from './popup/IncomeServiceDetailsPopup.vue'

export default {
    data() {
        return {
            from: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            to: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            languages: lang,
            //Table
            searchQuery: '',
            gridOptions: {
                alignedGrids: [],
                suppressHorizontalScroll: true,
                context: {
                    componentParent: this
                }
            },
            modules: AllCommunityModules,
            gridApi: null,
            defaultColDef: {
                sortable: true,
                editable: false,
                resizable: true,
                suppressMenu: true
            },
            columnDefs: [
                {
                headerName: 'ID',
                field: 'id_establishment',
                filter: true,
                width: 80
                },
                {
                headerName: 'Escuela',
                field: 'name_establishment',
                filter: true,
                width: 200
                },
                {
                headerName: 'Total Pago de Servicios',
                field: 'services_total',
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.services_total, '$ '),
                },
                {
                headerName: '% Comisión Pago de Servicios',
                field: 'percent_commission_tiptap',
                filter: true,
                valueFormatter: params => this.$options.filters.currencyPercentage(params.data.percent_commission_tiptap, ' %'),
                },
                {
                headerName: '$ Comisión Pago de Servicios',
                field: 'tiptap_amount_commission',
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.tiptap_amount_commission, '$ '),
                },
                {
                    headerName: 'Acciones',
                    filter: false,
                    cellRendererFramework: 'CellRendererActions',
                    cellRendererParams: {
                        showPopup: this.showPopup.bind(this)
                    }
                }
            ],
            columnDefsFooter: [
                {
                    field: 'id_establishment',
                    width: 80
                },
                {
                    field: 'name_establishment',
                    width: 200
                },
                {
                    field: 'services_total',
                    valueFormatter: params => this.$options.filters.currencySign(params.data.services_total, '$ '),
                },
                {
                    field: 'percent_commission_tiptap',
                },
                {
                    field: 'tiptap_amount_commission',
                    valueFormatter: params => this.$options.filters.currencySign(params.data.tiptap_amount_commission, '$ '),
                },
                {
                    headerName: 'Acciones',
                }
            ],
            sales: [],
            salesFooter: [],
            bottomGridOptions: {
                alignedGrids: [],
                defaultColDef: {
                    editable: false,
                    sortable: true,
                    resizable: true,
                    filter: true,
                    flex: 1,
                }
            },
            components: {
                CellRendererActions
            },
            // Popup Details
            popupData: [],
            popupActive: false,
        }
    },
    components: {
        DatePicker,
        AgGridVue,
        CellRendererActions,
        PopupDetail
    },
    created() {
        //Modules
        if(!reportModule.isRegistered) {
            this.$store.registerModule('reportModule', reportModule)
            reportModule.isRegistered = true
        }

        if(this.$acl.check('admin') || this.$acl.check('superadmin')) {
            this.loadTable()
        }
    },
    methods: {
        loadTable() {
            var dates = {from: this.isDate(this.from)?this.from.toISOString().split('T')[0] : '', to:this.isDate(this.to)?this.to.toISOString().split('T')[0] : ''};

            this.sales = []
            this.salesFooter = []
            this.setLoading(true)

            this.$store.dispatch("reportModule/incomesAdminSplit", {type: "services", dates})
                .then(() => {
                    let incomes = this.$store.getters['reportModule/getIncomesAdminSplit'];
                    this.sales = incomes.services_profits;
                    let s_footer = {}

                    if(incomes.services_profits && incomes.services_profits.length) {
                        s_footer.id_establishment = "Total";
                        s_footer.services_total = 0;
                        s_footer.percent_commission_tiptap = '';
                        s_footer.tiptap_amount_commission = 0;

                        incomes.services_profits.forEach(profit => {
                            s_footer.services_total += profit.services_total;
                            s_footer.tiptap_amount_commission += profit.tiptap_amount_commission;
                        })
                    }

                    this.salesFooter = [s_footer];
                })
                .finally(() => this.setLoading(false))
                .catch(error => {console.log(error)})
        },
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val);
        },
        isDate(val) {
            return val instanceof Date && !isNaN(val.valueOf())
        },
        myDateComparator(date1, date2) {
            var date1Number = this.monthToComparableNumber(date1);
            var date2Number = this.monthToComparableNumber(date2);
            if (date1Number === null && date2Number === null) {
                return 0;
            }
            if (date1Number === null) {
                return -1;
            }
            if (date2Number === null) {
                return 1;
            }
            return date1Number - date2Number;
        },
        monthToComparableNumber(date) {
            if (date === undefined || date === null || date.length !== 10) {
                return null;
            }
            var yearNumber = date.substring(6, 10);
            var monthNumber = date.substring(3, 5);
            var dayNumber = date.substring(0, 2);
            var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
            return result;
        },
        setFromTo(f,t) {
            this.from = f;
            this.to = t;
        },
        onFirstDataRendered: function() {
            this.gridColumnApi.autoSizeAllColumns();
        },
        showPopup(data) {
            // ToDo: Get amount after taxes from service and render in popup table
            data.forEach(t => {
                const guardian_commissions = t.transaction_detail.find(d => d.code_item == "CSERVICE")
                const institution_commissions = t.transaction_detail.find(d => d.code_item == "CSERVICE2")

                t.percent_guardian = guardian_commissions ? guardian_commissions.percent_item : 0
                t.tiptap_amount_commission_guardian = guardian_commissions ? guardian_commissions.amount_item : 0
                t.percent_main_institution = institution_commissions ? institution_commissions.percent_item : 0
                t.tiptap_amount_commission_main_institution = institution_commissions ? institution_commissions.amount_item : 0
            })
            this.popupData = data
            this.popupActive = true
        },
        updatePopupActive(val) {
            this.popupActive = val
        },
        setLoading(val) {
          if(val)
            this.$vs.loading({
              scale: 0.5,
            })
          else
            this.$vs.loading.close()
        },
    },
    watch: {
        from: function () {
            this.loadTable();
        },
        to: function () {
            this.loadTable();
        },
    },
    computed: {
        paginationPageSize() {
            if(this.gridApi) return this.gridApi.paginationGetPageSize()
            else return 50
        },
    },
    beforeMount() {
        this.gridOptions.alignedGrids.push(this.bottomGridOptions);
        this.bottomGridOptions.alignedGrids.push(this.gridOptions);
    },
    mounted() {
        this.gridApi = this.gridOptions.api
        this.gridColumnApi = this.gridOptions.columnApi;

        if(this.$vs.rtl) {
            const header = this.$refs.agGridTable.$el.querySelector(".ag-header-container")
            header.style.left = "-" + String(Number(header.style.transform.slice(11,-3)) + 9) + "px"
        }
    }
}

</script>
