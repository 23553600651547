<template>
    <div>
        <vx-card class="mt-2">
                <h5 style="margin-bottom:9px">Ingresos por Suscripciones Premium</h5>
                <!-- TABLE ACTION ROW -->
                <div class="flex flex-wrap justify-between items-center">
                    <!-- ITEMS PER PAGE -->
                    <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
                    <vs-dropdown vs-trigger-click class="cursor-pointer">
                        <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ subscriptions.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : subscriptions.length }} de {{ subscriptions.length }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                        </div>
                        <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
                        <vs-dropdown-menu>
                        <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                            <span>20</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                            <span>50</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                            <span>100</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                            <span>150</span>
                        </vs-dropdown-item>
                        </vs-dropdown-menu>
                    </vs-dropdown>
                    </div>
                    <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
                    <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
                        <p style="margin-top:-60px; margin-right:-40px; font-size:10px"> Desde</p>
                        <date-picker :language="languages.es" v-model="from" :first-day-of-week="1" label="Desde" placeholder="Desde" style="margin-right:-10px"></date-picker>
                        <p style="margin-top: -60px; margin-right:-40px; font-size:10px"> Hasta</p>
                        <date-picker :language="languages.es" v-model="to" type="datetime" label="Hasta" placeholder="Hasta" style="margin-right:-10px"></date-picker>
                        <vs-input
                            class="mb-4 md:mb-0 mr-4"
                            v-model="searchQuery"
                            @input="updateSearchQuery"
                            placeholder="Buscar..."/>
                        <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Exportar</vs-button>
                    </div>
                </div>
                <ag-grid-vue
                    ref="agGridTable"
                    :gridOptions="gridOptions"
                    class="ag-theme-material w-100 my-4 ag-grid-table"
                    style="max-height: 450px !important;"
                    :columnDefs="columnDefs"
                    :defaultColDef="defaultColDef"
                    :rowData="subscriptions"
                    @first-data-rendered="onFirstDataRendered"
                    rowSelection="multiple"
                    colResizeDefault="shift"
                    :animateRows="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :suppressPaginationPanel="true"
                    :enableRtl="$vs.rtl"
                    :modules="modules">
                </ag-grid-vue>
                <ag-grid-vue style="height: 100px; flex: none;"
                    class="ag-theme-material w-100 ag-grid-table total"
                    :gridOptions="bottomGridOptions"
                    :headerHeight="0"
                    :columnDefs="columnDefs"
                    :rowData="subscriptionsFooter"
                    :modules="modules">
                </ag-grid-vue>
                <vs-pagination
                    :total="totalPages"
                    :max="maxPageNumbers"
                    v-model="currentPage" />
        </vx-card>
    </div>
</template>

<script>
import * as lang from 'vuejs-datepicker/src/locale';
import { AgGridVue } from "@ag-grid-community/vue"
import reportModule from '@/store/report/moduleReport.js'
import DatePicker from "vuejs-datepicker";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { AllCommunityModules } from '@ag-grid-community/all-modules';

export default {
    data() {
        return {
            from: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            to: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            languages: lang,

            subscriptions: [],
            subscriptionsFooter: [],

            //Table
            searchQuery: '',
            gridOptions: {
                alignedGrids: [],
                suppressHorizontalScroll: true
            },
            modules: AllCommunityModules,
            gridApi: null,
            defaultColDef: {
                sortable: true,
                editable: false,
                resizable: true,
                suppressMenu: true
            },
            columnDefs: [
                {
                headerName: 'Acudiente',
                field: 'guardian_name',
                filter: true,
                },
                {
                headerName: 'Estudiante',
                field: 'student_name',
                filter: true,
                },
                {
                headerName: 'Cafetería - Escuela',
                field: 'institution_name',
                filter: true,
                },
                {
                headerName: '# Suscripciones',
                field: 'transactions',
                filter: true,
                },
                {
                headerName: 'Monto Suscripción',
                field: 'amount_annuity',
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.amount_annuity, '$ '),
                },
                {
                headerName: 'Total Suscripción',
                field: 'total_amount_annuity',
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.total_amount_annuity, '$ '),
                },
                {
                headerName: '% Comisión Tiptap',
                field: 'sa_percent_commission',
                filter: true,
                valueFormatter: params => this.$options.filters.currencyPercentage(params.data.sa_percent_commission, ' %'),
                },
                {
                headerName: '$ Comisión Tiptap',
                field: 'sa_amount_commission',
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.sa_amount_commission, '$ '),
                },
                {
                headerName: 'Ingresos Admin País',
                field: 'admin_incomes',
                filter: true,
                // valueGetter: params => {
                //         var admin_incomes = params.data.total_tiptap - params.data.sa_amount_commision
                //         return admin_incomes;
                //     },
                valueFormatter: params => this.$options.filters.currencySign((params.data.admin_incomes), '$ '),
                },
            ],
            sales: [],
            salesFooter: [],
            bottomGridOptions: {
                alignedGrids: [],
                defaultColDef: {
                    editable: false,
                    sortable: true,
                    resizable: true,
                    filter: true,
                    flex: 1,
                    minWidth: 100
                }
            },
            maxPageNumbers: 6,
            maxPageNumbersSummary: 8,

        }
    },
    components: {
        DatePicker,
        AgGridVue
    },
    created() {
        //Modules
        if(!reportModule.isRegistered) {
            this.$store.registerModule('reportModule', reportModule)
            reportModule.isRegistered = true
        }

        if(this.$acl.check('admin') || this.$acl.check('superadmin')) {
            this.loadTable()
        }
    },
    methods: {
        loadTable() {
            var dates = {from: this.isDate(this.from)?this.from.toISOString().split('T')[0] : '', to:this.isDate(this.to)?this.to.toISOString().split('T')[0] : ''};

            this.subscriptions = []
            this.subscriptionsFooter = []
            this.setLoading(true)

            this.$store.dispatch("reportModule/incomesAdminSplit", {type: "subscriptions", dates})
                .then(() => {
                    let incomes = this.$store.getters['reportModule/getIncomesAdminSplit'];
                    // this.sales = incomes.sales_profits;
                    // let s_footer = {}

                    // if(incomes.sales_profits && incomes.sales_profits.length) {
                    //     s_footer.name_institution = "Total";
                    //     s_footer.tiptap_total = 0;
                    //     s_footer.tiptap_amount_commission = 0;
                    //     s_footer.sa_amount_commission = 0;
                    //     s_footer.admin_incomes = 0;

                    //     incomes.sales_profits.forEach(profit => {
                    //         s_footer.tiptap_total += profit.tiptap_total;
                    //         s_footer.tiptap_amount_commission += profit.tiptap_amount_commission;
                    //         s_footer.sa_amount_commission += profit.sa_amount_commission;
                    //         s_footer.admin_incomes += profit.admin_incomes;
                    //     })
                    // }

                    // this.salesFooter = [s_footer];

                    let a_footer = {}
                    if(incomes.annuity_profits && incomes.annuity_profits.length) {

                        a_footer.guardian_name = "Total";
                        a_footer.total_amount_annuity = 0;
                        a_footer.sa_amount_commission = 0;
                        a_footer.admin_incomes = 0;

                        incomes.annuity_profits.forEach(profit => {
                            a_footer.total_amount_annuity += profit.total_amount_annuity;
                            a_footer.sa_amount_commission += profit.sa_amount_commission;
                            a_footer.admin_incomes += profit.admin_incomes;
                            let guardian = incomes.accounts.find(a => a.id_account == profit.id_guardian);
                            let student = incomes.accounts.find(a => a.id_account == profit.id_student);
                            profit.guardian_name = guardian ? guardian.name + ' ' + guardian.last_name : "";
                            profit.student_name = student ? student.name + ' ' + student.last_name : "";
                        })

                    }
                    this.subscriptions = incomes.annuity_profits;
                    this.subscriptionsFooter = [a_footer]
                })
                .finally(() => this.setLoading(false))
                .catch(error => {console.log(error)})
        },
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val);
        },
        isDate(val) {
            return val instanceof Date && !isNaN(val.valueOf())
        },
        myDateComparator(date1, date2) {
            var date1Number = this.monthToComparableNumber(date1);
            var date2Number = this.monthToComparableNumber(date2);
            if (date1Number === null && date2Number === null) {
                return 0;
            }
            if (date1Number === null) {
                return -1;
            }
            if (date2Number === null) {
                return 1;
            }
            return date1Number - date2Number;
        },
        monthToComparableNumber(date) {
            if (date === undefined || date === null || date.length !== 10) {
                return null;
            }
            var yearNumber = date.substring(6, 10);
            var monthNumber = date.substring(3, 5);
            var dayNumber = date.substring(0, 2);
            var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
            return result;
        },
        setFromTo(f,t) {
            this.from = f;
            this.to = t;
        },
        onFirstDataRendered: function() {
            this.gridColumnApi.autoSizeAllColumns();
        },
        setLoading(val) {
          if(val)
            this.$vs.loading({
              scale: 0.5,
            })
          else
            this.$vs.loading.close()
        },
    },
    watch: {
        from: function () {
            this.loadTable();
        },
        to: function () {
            this.loadTable();
        },
        '$store.state.windowWidth'(val) {
            if(val <= 576) {
                this.maxPageNumbers = 6;
                this.maxPageNumbersSummary = 8;
            }
        }
    },
    computed: {
        paginationPageSize() {
            if(this.gridApi) return this.gridApi.paginationGetPageSize()
            else return 50
        },
        totalPages() {
            if(this.gridApi) return this.gridApi.paginationGetTotalPages()
            else return 0
        },
        currentPage: {
            get() {
                if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
                else return 1
            },

            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        },
    },
    beforeMount() {
        this.gridOptions.alignedGrids.push(this.bottomGridOptions);
        this.bottomGridOptions.alignedGrids.push(this.gridOptions);
    },
    mounted() {
        this.gridApi = this.gridOptions.api
        this.gridColumnApi = this.gridOptions.columnApi;

        if(this.$vs.rtl) {
            const header = this.$refs.agGridTable.$el.querySelector(".ag-header-container")
            header.style.left = "-" + String(Number(header.style.transform.slice(11,-3)) + 9) + "px"
        }
    }
}

</script>
