<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
        <vx-tooltip text="Ver Detalle" class="float-left">
            <feather-icon icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="clickHandler()" />
        </vx-tooltip>
    </div>
</template>

<script>
    export default {
        name: 'CellRendererActions',
        props: {
            data: {
                type: Object,
                default: () => {}
            }
        },
        methods: {
            clickHandler() {
                this.params.showPopup(this.params.data.detail)
            }
        }
    }
</script>
<style>
    .vs-popup--content { 
        height:100% !important;
    }
</style>
